<template>
  <div class="mb-5 flex items-center">
    <div class="mr-5 ml-5 text-sm font-medium text-neutral-400 sm:ml-0">
      {{ text }}
    </div>
    <div class="flex-grow h-1 bg-neutral-100"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
